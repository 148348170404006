import { useState, useEffect } from "react";
import { Box, Flex, Text, Image, Input, Button, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAppSelector } from "../../store/hooks";
import { InteractedProfile } from "../../../../server/types/apiTypes";
import defaultProfilePic from "../../assets/profileAnonPicInverse.png";
import { getStatsigClient } from "../../utils/statsig";
import { shareProfile } from "../../utils/shareUtils";
import { useToast } from "@chakra-ui/react";
import { sizing } from "../../styles/sizing";
import { useNavigate } from "react-router-dom";
const AskYourFriendsTab = () => {
  const authState = useAppSelector((state) => state.auth);
  const [recentProfiles, setRecentProfiles] = useState<InteractedProfile[]>([]);
  const toast = useToast();
  const [question, setQuestion] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const profileRes = await axios.get(`/api/uniqueInteractedUsers`);
      if (profileRes.status === 200) {
        setRecentProfiles(profileRes.data);
      }
    })();
  }, []);

  const handleShareAndCopy = async () => {
    const userId = authState.userId;
    if (!userId) {
      console.error("No userId found");
      return;
    }
    getStatsigClient().logEvent("click_share_link");

    if (authState.uniqId) {
      await shareProfile(authState.uniqId, toast);
    }
  };

  const handleSubmit = () => {
    // Placeholder for submission logic
    console.log("Question submitted:", question);
    if (question) {
      navigate("/ayfResults", {
        state: {
          question,
        },
      });
    }
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        mb="100px"
        width="100%"
        maxW="100%"
        mx="auto"
        overflowY="auto"
        overflowX="hidden"
        flex="1"
      >
        {/* Horizontal scrolling profiles section */}
        <Flex direction="column" width="100%" alignItems="center">
          <Box
            width="100%"
            maxW="calc(100% - 16px)"
            overflowX="auto"
            height="110px"
            display="flex"
            alignItems="center"
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
            mb={2}
          >
            <Flex gap={2} width="max-content" px={1}>
              <Box
                cursor="pointer"
                transition="transform 0.2s"
                _hover={{ transform: "scale(1.05)" }}
                p={1}
                onClick={handleShareAndCopy}
              >
                <Flex direction="column" align="center">
                  <Box
                    borderRadius="full"
                    width="70px"
                    height="70px"
                    overflow="hidden"
                    bg="#FF0050"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <AddIcon w={6} h={6} color="white" />
                  </Box>
                  <Text
                    fontSize="small"
                    color="white"
                    mt={1}
                    textAlign="center"
                    noOfLines={1}
                  >
                    Invite
                  </Text>
                </Flex>
              </Box>
              {recentProfiles.map((profile) => (
                <Box
                  key={profile.userId}
                  as={Link}
                  to={`/profile/${profile.uniqueId}`}
                  cursor="pointer"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.05)" }}
                  p={1}
                  onClick={() => {
                    getStatsigClient().logEvent(
                      "click_recent_profile",
                      undefined,
                      {
                        profileId: profile.userId || "",
                      }
                    );
                  }}
                >
                  <Flex direction="column" align="center">
                    <Box
                      borderRadius="full"
                      width="70px"
                      height="70px"
                      overflow="hidden"
                    >
                      <Image
                        src={profile.profilePic || defaultProfilePic}
                        alt={profile.displayName || "Profile"}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                      />
                    </Box>
                    <Text
                      fontSize="small"
                      color="white"
                      mt={1}
                      textAlign="center"
                      noOfLines={1}
                    >
                      {profile.displayName?.split(" ")[0] || "Profile"}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>

        {/* Question input section */}
        <Flex
          direction="column"
          width="100%"
          maxW="calc(100% - 32px)"
          gap={4}
          mt={10}
        >
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color="white"
            textAlign="center"
          >
            Need advice or recommendations? <br></br> Ask your friends now!
          </Text>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              height="100%"
              children={<SearchIcon color="gray.400" />}
            />
            <Input
              placeholder="Ask your friends anything..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              fontSize={{ base: "md", md: "md", lg: "lg" }}
              fontWeight="bold"
              height={{ base: "56px", md: "58px", lg: "60px" }}
              bg="white"
              color="black"
              _placeholder={{ color: "gray.400" }}
              borderRadius="full"
              paddingLeft="40px"
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderColor: "white",
              }}
            />
          </InputGroup>
          <Box
            position="fixed"
            bottom="0"
            left="0"
            right="0"
            bg="#1c1c1c"
            p={4}
            textAlign="center"
            width="100%"
            maxWidth="100vw"
          >
            <Button
              width="100%"
              maxW={sizing.maxAppContentWidthPx}
              mx="auto"
              onClick={handleSubmit}
              backgroundColor="#FF0050"
              color="white"
              height={{ base: "56px", md: "58px", lg: "60px" }}
              borderRadius="30px"
              _hover={{ bg: "#ef004b" }}
              fontSize={{ base: "sm", md: "md", lg: "lg" }}
            >
              Submit
            </Button>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default AskYourFriendsTab;
