import React, { useState } from "react";
import { Box, BoxProps, Flex, Image, Text } from "@chakra-ui/react";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface ExpandableInboxItemProps extends Omit<BoxProps, "onClick"> {
  /** The name of the person answering */
  receiverName: string;
  /** URL of the receiver's profile picture */
  receiverProfilePic: string;
  /** The answer text */
  answer: string;
}

const ExpandableInboxItem: React.FC<ExpandableInboxItemProps> = ({
  receiverName,
  receiverProfilePic,
  answer,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      bg="white"
      borderRadius="45px"
      borderWidth="0.25px"
      borderColor="white"
      w="calc(100% - 16px)"
      maxW="568px"
      p={4}
      position="relative"
      cursor="pointer"
      transition="all 0.2s"
      onClick={handleClick}
      role="button"
      tabIndex={0}
      overflow="hidden"
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      _hover={{
        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
        transform: "translateY(-1px)",
      }}
      {...props}
    >
      <Flex alignItems="flex-start" gap={4}>
        <Image
          src={receiverProfilePic || defaultProfilePic}
          alt={`${receiverName}'s profile picture`}
          borderRadius="full"
          objectFit="cover"
          boxSize="52px"
          flexShrink={0}
        />

        <Box flex={1}>
          {/* Username */}
          <Flex alignItems="center" justifyContent="space-between" gap={1}>
            <Flex alignItems="center" gap={1}>
              <Text color="#FF0050" fontWeight="bold" fontSize="small">
                {receiverName}
              </Text>
              <Text color="black" fontSize="small">
                answered:
              </Text>
            </Flex>
          </Flex>

          {/* Answer text */}
          <Text
            color="black"
            fontWeight="bold"
            textAlign="left"
            noOfLines={isExpanded ? undefined : 2}
            minHeight={isExpanded ? undefined : "3rem"}
            whiteSpace="pre-wrap"
          >
            {answer}
          </Text>
        </Box>

        {/* Expand/Collapse Icon */}
        {isExpanded ? (
          <ChevronUpIcon boxSize={6} color="black" />
        ) : (
          <ChevronDownIcon boxSize={6} color="black" />
        )}
      </Flex>
    </Box>
  );
};

export default ExpandableInboxItem;
