import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  Center,
  Icon,
  Image,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAppSelector } from "../store/hooks";
import { getStatsigClient } from "../utils/statsig";
import { SearchIcon } from "@chakra-ui/icons";
import lightbulb from "../assets/lightbulb.svg";
import ExpandableInboxItem from "./ExpandableInboxItem";
import { MultiAskResponse } from "../../../server/types/apiTypes";
import { sizing } from "../styles/sizing";
interface LocationState {
  multiAskId?: string;
  question?: string;
}

const AskYourFriendsResultsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { multiAskId, question } = (location.state as LocationState) || {};
  const [responses, setResponses] = useState<MultiAskResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const authState = useAppSelector((state) => state.auth);
  const [displayedQuestion, setDisplayedQuestion] = useState<string>("");
  const hasInitialFetchRef = useRef(false);

  useEffect(() => {
    if (question) {
      setDisplayedQuestion(question);
    }
  }, [question]);

  useEffect(() => {
    const fetchExistingResponses = async () => {
      if (hasInitialFetchRef.current) return;
      hasInitialFetchRef.current = true;

      try {
        setIsLoading(true);
        const response = await axios.get("/api/getMultiAskResponses", {
          params: { multiAskId },
        });

        if (response.status === 200) {
          const newResponses = response.data.responses.map((res: any) => ({
            userId: res.userId,
            userName: res.userName,
            userProfilePic: res.userProfilePic,
            type: res.type,
            content: res.content,
          }));
          setDisplayedQuestion(response.data.question);
          setResponses(newResponses);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching responses:", error);
        setIsLoading(false);
      }
    };

    const askFriends = async () => {
      if (hasInitialFetchRef.current) return;
      hasInitialFetchRef.current = true;

      try {
        setIsLoading(true);
        const response = await axios.post("/api/askFriends", {
          query: question,
        });

        if (response.data.responses) {
          const newResponses = response.data.responses.map((res: any) => ({
            userId: res.userId,
            userName: res.userName,
            userProfilePic: res.userProfilePic,
            type: res.type,
            content: res.content,
          }));
          setResponses(newResponses);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error asking friends:", error);
        setIsLoading(false);
      }
    };

    if (multiAskId) {
      fetchExistingResponses();
    } else if (question) {
      askFriends();
    }
  }, [multiAskId, question, authState.userId]);

  const handleBack = () => {
    getStatsigClient().logEvent("click_back_from_multi_ask_results");
    navigate(-1);
  };

  return (
    <Box width="100%" height="100vh" bg="#1c1c1c" position="relative">
      <Flex bg="#1C1C1C" minH="100vh">
        <Box
          maxW={sizing.maxAppWidthPx}
          mx="auto"
          p={4}
          textAlign="left"
          width="full"
          position="relative"
        >
          <Flex align="center" justify="space-between" mb={8}>
            <Button
              leftIcon={<ChevronLeftIcon boxSize={5} />}
              backgroundColor="#1C1C1C"
              color="white"
              borderRadius="md"
              _hover={{ bg: "#2C2C2C" }}
              size="md"
              onClick={handleBack}
            >
              Back
            </Button>
          </Flex>

          <Flex
            direction="column"
            alignItems="center"
            gap={4}
            mb="100px"
            overflowY="auto"
          >
            {/* Question Display */}
            <Box
              width="100%"
              maxW={sizing.maxAppContentWidthPx}
              bg="white"
              p={4}
              borderRadius="full"
              display="flex"
              alignItems="center"
            >
              <Icon as={SearchIcon} color="gray.400" mr={2} />
              <Text
                color="black"
                fontSize={{ base: "md", md: "md", lg: "lg" }}
                fontWeight="bold"
              >
                {displayedQuestion}
              </Text>
            </Box>

            <Flex align="center" justify="center" gap={2} marginBottom="20px">
              <Image src={lightbulb} alt="Lightbulb icon" />
              <Text color="whiteAlpha.800" fontSize="10px">
                Tap Back to ask a new question
              </Text>
            </Flex>

            {/* Loading State */}
            {isLoading ? (
              <Center py={8}>
                <Spinner
                  thickness="4px"
                  speed="0.75s"
                  emptyColor="#1c1c1c"
                  color="#FF0050"
                  size="xl"
                />
              </Center>
            ) : (
              // Responses List
              responses.map((response, index) => (
                <ExpandableInboxItem
                  key={`${response.userId}-${index}`}
                  receiverName={response.userName}
                  receiverProfilePic={response.userProfilePic}
                  answer={response.content}
                />
              ))
            )}

            {!isLoading && responses.length === 0 && (
              <Text color="white" textAlign="center" mt={8}>
                No responses yet. Check back later!
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default AskYourFriendsResultsPage;
