import { getStatsigClient } from "./statsig";

export const shareProfile = async (uniqId: string, toast: any) => {
  const userDotLink =
    process.env.NODE_ENV === "production"
      ? `https://dots.link/profile/${uniqId}`
      : `http://localhost:5173/profile/${uniqId}`;

  try {
    // For mobile devices, prioritize native share
    if (navigator.share && window.innerWidth < 768) {
      await navigator.share({
        title:
          "Check this out! You can ask me anything and get an instant reply",
        url: userDotLink,
      });
      console.log("Content shared successfully");
      getStatsigClient().logEvent("share_link_with_native_share_success");
    } else {
      // For desktop or if share fails, copy to clipboard
      await navigator.clipboard.writeText(userDotLink);
      getStatsigClient().logEvent("share_link_with_clipboard_success");
      toast({
        title: "Link Copied",
        description:
          "The link has been copied to your clipboard. Share now!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
        variant: "subtle",
        colorScheme: "gray",
      });
    }
  } catch (error: any) {
    console.error("Error:", error);
    // Fallback to clipboard if share fails
    if (error.name === "AbortError") {
      // This is expected if the user clicks away before the share dialog is closed, and we cannot copy to clipboard in this case
      return;
    }
    try {
      await navigator.clipboard.writeText(userDotLink);
      getStatsigClient().logEvent("share_link_with_clipboard_success");
      toast({
        title: "Link Copied",
        description:
          "The link has been copied to your clipboard. Share now!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
        variant: "subtle",
        colorScheme: "gray",
      });
    } catch (clipboardError) {
      console.error("Clipboard error:", clipboardError);
      getStatsigClient().logEvent("share_link_with_clipboard_failure");
      toast({
        title: "Error",
        description: "Could not share or copy link. Please try again.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }
};
